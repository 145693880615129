import {
  CampaignMember,
  CampaignMemberStatus,
  Contact,
  Prospect,
  prospectToContact,
} from "@/models";
import { TrackedEventName } from "@/third-party/tracking";
import ContactAvatar from "@components/ContactAvatar";
import RoundedBox from "@components/RoundedBox";
import {
  Box,
  BoxProps,
  IconButton,
  Popper,
  Stack,
  Typography,
} from "@mui/material";
import { getFullName } from "@utils/text";
import { useMemo, useState } from "react";
import { Link } from "react-router-dom";
import BlacklistButton from "./BlacklistButton";
import Tracked from "./Tracked";
import { HelpIcon, InfoIcon, LinkedinIcon, WebsiteIcon } from "./icons";

type ProspectProps = {
  contact?: Contact;
  prospect?: Prospect;
  member?: CampaignMember;
  showBlacklistButton?: boolean;
} & BoxProps;

type ValidMemberStatus =
  | CampaignMemberStatus.CONTACTED
  | CampaignMemberStatus.REPLIED
  | CampaignMemberStatus.WARM_REPLY
  | CampaignMemberStatus.SCHEDULING
  | CampaignMemberStatus.LAWYER_HANDLING
  | CampaignMemberStatus.MEETING_BOOKED
  | CampaignMemberStatus.WORK_BILLED;

const BulletPointList = ({ text }: { text: string }) => {
  return <Box component="ul">
    {text.split('\n').map((item, index) => (
      <Typography component="li" variant="body2" key={index}>{item.replace(/^- /, '')}</Typography>
    ))}
  </Box>
}

export default function ContactBox({
  contact,
  prospect,
  member,
  showBlacklistButton = false,
  ...boxProps
}: ProspectProps) {
  const contactPerson = contact
    ? contact
    : prospect
      ? prospectToContact(prospect)
      : null;


  // Show buttons
  const showActionButtons = useMemo(() => {
    return showBlacklistButton;
  }, [showBlacklistButton]);

  // Create state to manage popper visibility
  const [summaryElement, setSummaryElement] = useState<HTMLElement | null>(null);
  const [reasoningElement, setReasoningElement] = useState<HTMLElement | null>(null);

  const handleShowSummary = (event: React.MouseEvent<HTMLElement>) => {
    setSummaryElement(summaryElement ? null : event.currentTarget);
  };

  const handleHideSummary = () => {
    setSummaryElement(null);
  };

  const handleShowReasoning = (event: React.MouseEvent<HTMLElement>) => {
    setReasoningElement(reasoningElement ? null : event.currentTarget);
  }

  const handleHideReasoning = () => {
    setReasoningElement(null);
  }


  const hasSummary = Boolean((contactPerson?.summary?.length || 0) > 20);
  const showSummary = Boolean(summaryElement);
  const summaryPopperId = showSummary ? "summary-popper" : undefined;

  const hasReasoning = Boolean((prospect?.reasoning?.length || 0) > 5);
  const showReasoning = Boolean(reasoningElement);
  const reasoningPopperId = showReasoning ? "reasoning-popper" : undefined;

  return contactPerson ? (
    <RoundedBox key={contactPerson.id} p={2} {...boxProps}>
      <Stack direction="row" spacing={2} alignItems={"center"}>
        <ContactAvatar contact={contactPerson} size={55} />
        <Box lineHeight={"1em"}>
          <Typography variant="subtitle2">
            {getFullName(contactPerson.firstName, contactPerson.lastName)}
          </Typography>
          <Typography variant="caption" color="text.secondary">
            {contactPerson?.title || ""} @ {contactPerson?.company?.name || ""}
          </Typography>
          <Stack direction="row" spacing={0} alignItems={"center"}>
            {hasReasoning && (
              <IconButton
                size="small"
                onMouseEnter={handleShowReasoning}
                onMouseLeave={handleHideReasoning}
              >
                <HelpIcon fontSize="small" />
              </IconButton>
            )}
            {hasSummary && (
              <IconButton
                size="small"
                onMouseEnter={handleShowSummary}
                onMouseLeave={handleHideSummary}
              >
                <InfoIcon fontSize="small" />
              </IconButton>
            )}
            {!!contactPerson?.linkedinUrl && (
              <Tracked
                onClick={{ name: TrackedEventName.CONTACT_LINKEDIN_VIEWED }}
              >
                <Link to={contactPerson?.linkedinUrl} target="_blank">
                  <IconButton size="small">
                    <LinkedinIcon fontSize="small" />
                  </IconButton>
                </Link>
              </Tracked>
            )}
            {!!contactPerson?.company?.websiteUrl && (
              <Tracked
                onClick={{ name: TrackedEventName.CONTACT_WEBSITE_VIEWED }}
              >
                <Link to={contactPerson?.company?.websiteUrl} target="_blank">
                  <IconButton size="small">
                    <WebsiteIcon fontSize="small" />
                  </IconButton>
                </Link>
              </Tracked>
            )}
          </Stack>
        </Box>
      </Stack>
      {showActionButtons && (
        <Box mt={1}>
          <Stack
            direction="row"
            spacing={1}
            alignItems={"center"}
            justifyContent={"flex-end"}
          >
            {showBlacklistButton && (
              <BlacklistButton contact={contactPerson}>Remove</BlacklistButton>
            )}
          </Stack>
        </Box>
      )}
      <Popper id={reasoningPopperId} open={showReasoning} anchorEl={reasoningElement}>
        <Box
          width={300}
          bgcolor={"background.info"}
          border={1}
          borderColor={"divider"}
          p={2}
        >
          <BulletPointList text={prospect?.reasoning || ''} />
        </Box>
      </Popper>
      <Popper id={summaryPopperId} open={showSummary} anchorEl={summaryElement}>
        <Box
          width={300}
          bgcolor={"background.info"}
          border={1}
          borderColor={"divider"}
          p={2}
        >
          <Typography variant="body2">{contactPerson?.summary}</Typography>
        </Box>
      </Popper>
    </RoundedBox>
  ) : null;
}
