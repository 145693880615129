

import { ROUTE_INSTANT_MAILBOXES } from "@/AuthenticatedAppRoutes";
import NavButton from "@components/layout/navigation/NavButton";
import { useAppContext } from "@context/AppContext";
import { Divider, Stack, Typography } from "@mui/material";
import { useEffect } from "react";

type GoToInstantMailboxesProps = {
}

const GoToInstantMailboxes = ({ }: GoToInstantMailboxesProps) => {
  const { mailingInfraStructureTag } = useAppContext();

  useEffect(() => {
    console.log({ mailingInfraStructureTag })
  }, [mailingInfraStructureTag])


  return (
    <>

      <Stack
        direction={{
          xs: "column",
          sm: "row",
        }}
        alignItems={{
          xs: "flex-start",
          sm: "center",
        }}
        spacing={2}
      >
        <Stack direction={"column"} spacing={1} width={300}>
          <Typography color="text.secondary" variant="subtitle1">
            Mailing Infrastructure
          </Typography>
          <Typography variant="caption" color={"text.secondary"}>
            Manage your Mailing Infrastructure
          </Typography>
        </Stack>

        <NavButton
          to={ROUTE_INSTANT_MAILBOXES}
          title="Mailing Infrastructure"
          label="Go to Mailing Infrastructure"
        />
      </Stack>
      <Divider />
    </>
  );
};

export default GoToInstantMailboxes;