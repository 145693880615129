import { SubscriptionState, useInstantMailboxesContext } from "@context/InstantMailboxesContext";
import { Stack, TextField, Typography } from "@mui/material";
import { MOST_CUSTOMERS_CHOOSE_HOW_MANY_LEADS } from "./constants";


const ExtraLeadsPerMonth = () => {
  const { subscriptionState, extraLeadsPerMonth, setExtraLeadsPerMonth } = useInstantMailboxesContext()


  const handleExtraLeadsPerMonthChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;

    // Allow empty string to support backspace
    if (value === "") {
      setExtraLeadsPerMonth(0);
      return;
    }

    // Convert to number and validate
    const numValue = parseInt(value);
    if (numValue >= 0) { // Only update if non-negative
      setExtraLeadsPerMonth(numValue);
    }
  }


  return (
    <Stack
      direction={{ sm: "row" }}
      alignItems={{ sm: "center" }}
      spacing={3}
      padding={3}
    >
      <Stack direction={"column"} spacing={0} width={300} >
        <Typography variant="h5">Desired Leads Per Month</Typography>
        {subscriptionState !== SubscriptionState.ACTIVE_OR_ALLOCATED && (
          <Typography variant="caption">*most people choose {MOST_CUSTOMERS_CHOOSE_HOW_MANY_LEADS}</Typography>
        )}
      </Stack>
      <Stack direction={"column"} spacing={1} width={'50%'} >
        {subscriptionState === SubscriptionState.REQUESTED || subscriptionState === SubscriptionState.ACTIVE_OR_ALLOCATED ? (
          <TextField
            id="leadsPerMonth"
            type="number"
            inputProps={{
              min: 1,
              step: 1, // Ensures only integer values
              readOnly: true
            }}
            value={extraLeadsPerMonth || ""} // Show empty string when value is 0
          />
        ) : (
          <TextField
            id="leadsPerMonth"
            autoFocus
            type="number"
            inputProps={{
              min: 1,
              step: 1 // Ensures only integer values
            }}
            value={extraLeadsPerMonth || ""} // Show empty string when value is 0
            onChange={handleExtraLeadsPerMonthChange}
          />
        )}
      </Stack>
    </Stack>


  )
}

export default ExtraLeadsPerMonth;