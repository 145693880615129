import { AvailablePricingPlans, PricingPlan } from "@/models";
import { Button, Card, CardActions, CardContent, Chip, Typography } from "@mui/material";



export const PlanCardDetails: { [key in AvailablePricingPlans]: {
  title: string;
  subtitle: string;
  cost: number;
  features: string[];
} } = {
  [PricingPlan.STARTER]: {
    title: "Starter",
    subtitle: "Annual contract - billed monthly",
    cost: 1000,
    features: [
      "Up to 3 active Email campaigns",
      "9 leads per month or 3 meetings per month",
      "One time kickoff GTM consultancy and messaging session",
      "Quarterly Customer Success Office Hours",
      "48hr support SLA",
    ],
  },
  [PricingPlan.GROWTH]: {
    title: "Growth",
    subtitle: "Annual contract - billed monthly",
    cost: 1700,
    features: [
      "Everything in Starter",
      "Email and LinkedIn campaigns",
      "Up to 10 active campaigns",
      "Minimum of 13 leads per month or 7 meetings per month",
      "Quarterly GTM consultancy and messaging session",
      "Dedicated CS Manager and Technical CS manager with optional weekly touchpoint",
      "Reply warming and meeting booked functionality",
      "12hr support SLA",
    ],
  },
  [PricingPlan.GROWTH_30]: {
    title: "Growth Plus",
    subtitle: "Annual contract - billed monthly",
    cost: 1700,
    features: [
      "Everything in Growth",
      "Email and LinkedIn campaigns",
      "Up to 30 active campaigns",
      "Minimum of 40 leads per month or 20 meetings per month",
    ],
  },
  [PricingPlan.GROWTH_40]: {
    title: "Growth 40",
    subtitle: "Annual contract - billed monthly",
    cost: 1700,
    features: [
      "Everything in Growth",
      "Email and LinkedIn campaigns",
      "Up to 40 active campaigns",
      "Minimum of 50 leads per month or 25 meetings per month",
    ],
  },
  [PricingPlan.GROWTH_50]: {
    title: "Growth 50",
    subtitle: "Annual contract - billed monthly",
    cost: 1700,
    features: [
      "Everything in Growth",
      "Email and LinkedIn campaigns",
      "Up to 50 active campaigns",
      "Minimum of 60 leads per month or 30 meetings per month",
    ],
  },
};

const PlanCard = ({
  plan,
  isCurrent,
  onViewMore,
}: {
  plan: AvailablePricingPlans;
  isCurrent: boolean;
  onViewMore: () => void;
}) => {
  return (
    <Card>
      <CardContent>
        <Typography variant="h5" component="div">
          {PlanCardDetails[plan].title} {isCurrent && <Chip label="Current" color="primary" size="small" sx={{ ml: 1 }} />}
        </Typography>
        <Typography variant="subtitle1" color="primary">
          {PlanCardDetails[plan].subtitle}
        </Typography>
        <Typography variant="h4" component="div" sx={{ mt: 2 }}>
          ${PlanCardDetails[plan].cost}/month
        </Typography>
      </CardContent>
      <CardActions>
        <Button variant="outlined" fullWidth onClick={onViewMore}>
          View More Info
        </Button>
      </CardActions>
    </Card>
  );
};

export default PlanCard

