import UIMessages from "@/UIMessages";
import { ApolloProvider } from "@apollo/client";
import {
  Authenticator,
  CheckboxField,
  WithAuthenticatorProps,
  useAuthenticator,
  withAuthenticator,
} from "@aws-amplify/ui-react";

import "@aws-amplify/ui-react/styles.css";
import createApolloClient from "@clients/apolloClient";
import { AppProvider } from "@context/AppContext";
import { UIMessageProvider } from "@context/UIMessageContext";
import {
  Box,
  Button,
  CssBaseline,
  Fade,
  LinearProgress,
  Stack,
  ThemeProvider,
  Typography,
  createTheme
} from "@mui/material";
import { Amplify, Auth } from "aws-amplify";
import React, { Suspense, useMemo } from "react";
import { Link } from "react-router-dom";
import "./App.css";

import LogoIcon from "@components/icons/LogoIcon";
import Router from "@components/layout/Router";
import { CampaignsProvider } from "@context/CampaignsContext";
import { ConversationsProvider } from "@context/ConversationsContext";
import { themeOptions } from "@design/theme";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import AuthenticatedAppRoutes, {
  ROUTE_DASHBOARD,
  ROUTE_MEETINGS_AUTHENTICATE,
  ROUTE_MEETINGS_BOOK,
} from "./AuthenticatedAppRoutes";
import config from "./config";

import { MaintenanceSplash } from "@components/MaintenanceSplash";
import { CampaignMembersProvider } from "@context/CampaignMembersContext";
import { StatisticsProvider } from "@context/StatisticsContext";
import CampaignMemberMeetingPage from "@pages/meetings/CampaignMemberMeetingPage";
import TimeAgo from "javascript-time-ago";
import en from "javascript-time-ago/locale/en.json";
import { Route, Routes } from "react-router-dom";
import { LoadIntercom } from "./third-party/intercom";
import LoadTracking from "./third-party/tracking";

TimeAgo.addDefaultLocale(en);

/**
 * This is a hack to fix the issue with the Amplify library treating all incoming urls as OAuth responses.
 * https://github.com/aws-amplify/amplify-js/issues/9208#issuecomment-1309890756*
 */
// @ts-ignore
const _handleAuthResponse = Auth._handleAuthResponse.bind(Auth);
// @ts-ignore
Auth._handleAuthResponse = (url: string) => {
  if (url.includes(ROUTE_MEETINGS_AUTHENTICATE)) return;
  return _handleAuthResponse(url);
};

Amplify.configure({
  Auth: {
    region: config.AWS_REGION,
    userPoolId: config.AWS_COGNITO_USER_POOL_ID,
    userPoolWebClientId: config.AWS_COGNITO_USER_POOL_WEB_CLIENT_ID,

    oauth: {
      redirectSignIn: ROUTE_DASHBOARD,
      redirectSignUp: ROUTE_DASHBOARD,
      redirectSignOut: ROUTE_DASHBOARD,
    },
  },
});

const Loading = () => <LinearProgress color="secondary" />;

const App: React.FC<WithAuthenticatorProps> = () => {
  const theme = createTheme(themeOptions);

  // Configure Amplify with Cognito
  const apolloClient = useMemo(() => {
    return createApolloClient();
  }, []);

  return (
    <Suspense fallback={<Loading />}>
      <ApolloProvider client={apolloClient}>
        <ThemeProvider theme={theme}>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <Router>
              <Routes>
                <Route
                  path={ROUTE_MEETINGS_BOOK}
                  element={<CampaignMemberMeetingPage />}
                />
                <Route path="*" element={<AuthenticatedAppContent />} />
              </Routes>
            </Router>
          </LocalizationProvider>
        </ThemeProvider>
      </ApolloProvider>
    </Suspense>
  );
};

const AppContent = () => {
  return (
    <UIMessageProvider>
      <AppProvider>
        <CssBaseline />
        <LoadTracking />
        <MaintenanceSplash />
        <CampaignsProvider>
          <ConversationsProvider>
            <CampaignMembersProvider>
              <StatisticsProvider>
                <UIMessages />
                <AuthenticatedAppRoutes />
                <LoadIntercom />
              </StatisticsProvider>
            </CampaignMembersProvider>
          </ConversationsProvider>
        </CampaignsProvider>
      </AppProvider>
    </UIMessageProvider>
  );
};

const TermsOfServiceLabel = ({
  prefix = "I have read and consent to the "
}: { prefix?: string }) => {
  return (
    <Typography variant="body2">
      {prefix}
      <Link
        to={config.TOS_URL}
        target="_blank"
      >
        Terms
      </Link> and <Link
        to={config.PRIVACY_URL}
        target="_blank"
      >
        Privacy Policy
      </Link>.
    </Typography>
  )
}

const TermsOfServiceFooter = ({
  prefix
}: { prefix?: string }) => {
  return (
    <Stack
      direction="row"
      spacing={2}
      alignItems="center"
      justifyContent="center"
      px={2}
      mb={2}
    >
      <TermsOfServiceLabel
        prefix={prefix}
      />
    </Stack>
  );
};

const AuthenticatedAppContent = withAuthenticator(AppContent, {
  variation: "modal",
  className: "authenticator",

  components: {
    Header() {
      return (
        <Fade in={true} timeout={2000}>
          <Box color="white" pb={2} textAlign={"center"} mb={2}>
            <Stack direction="column" spacing={1} alignItems="center">
              <LogoIcon height={20} />
              <Typography
                variant="subtitle1"
                gutterBottom
                component={"h1"}
                mt={-4}
              >
                Grow your business
              </Typography>
            </Stack>
          </Box>
        </Fade>
      );
    },
    SignIn: {
      Footer() {
        const { toResetPassword } = useAuthenticator();

        return <Stack
          direction="column"
          spacing={2}
          alignItems="center"
          justifyContent="center"
          px={4}
          mb={2}
          className="amplify-text"
        >
          <Button
            onClick={toResetPassword}
            variant="outlined"
            fullWidth
            style={{
              color: "white",
            }}
          >
            Reset Password
          </Button>
          <TermsOfServiceFooter prefix="By signing in, I agree to " />
        </Stack>;
      },
    },
    SignUp: {
      Footer() {
        return null;
      },
      FormFields() {
        const { validationErrors } = useAuthenticator();

        return (
          <>
            {/* Re-use default `Authenticator.SignUp.FormFields` */}
            <Authenticator.SignUp.FormFields />

            {/* Append & require Terms and Conditions field to sign up  */}
            <CheckboxField
              errorMessage={validationErrors.acknowledgement as string}
              hasError={!!validationErrors.acknowledgement}
              name="acknowledgement"
              value="yes"
              label={<TermsOfServiceLabel prefix="I agree to the " />}
            />
          </>
        );
      },
    },
  },
  services: {
    async validateCustomSignUp(formData) {
      if (!formData.acknowledgement) {
        return {
          acknowledgement: 'You must agree to the Terms and Conditions',
        };
      }
    },
  },
  formFields: {
    signIn: {
      username: {
        label: "Email:",
        placeholder: "Enter your email",
      },
    },
    signUp: {
      given_name: {
        label: "First Name:",
        placeholder: "Enter your First Name:",
        isRequired: true,
        order: 1,
      },
      family_name: {
        label: "Last Name:",
        placeholder: "Enter your Last Name:",
        isRequired: true,
        order: 2,
      },
      username: {
        label: "Email:",
        placeholder: "Enter your email:",
        isRequired: true,
        type: "email",
      },
      password: {
        label: "Password:",
        placeholder: "Enter your Password:",
        isRequired: false,
      },
      confirm_password: {
        label: "Confirm Password:",
      },
      phone_number: {
        label: "Phone Number:",
        placeholder: "Enter your Phone Number:",
        isRequired: true,
      },
    },
    forceNewPassword: {
      password: {
        placeholder: "Enter your Password:",
      },
    },
    resetPassword: {
      username: {
        placeholder: "Enter your email:",
      },
    },
    confirmResetPassword: {
      confirmation_code: {
        placeholder: "Enter your Confirmation Code:",
        label: "Confirmation Code:",
        isRequired: false,
      },
      confirm_password: {
        placeholder: "Enter your Password Please:",
      },
    },
  },
});

export default App;
