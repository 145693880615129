import SectionCard from "@components/layout/SectionCard";
import LoadingSpinner from "@components/layout/page/LoadingSpinner";
import Page from "@components/layout/page/Page";
import PageContent from "@components/layout/page/PageContent";
import { useAppContext } from "@context/AppContext";
import { InstantMailboxesProvider } from "@context/InstantMailboxesContext";
import { Divider } from "@mui/material";
import Costs from "./Costs";
import ExtraLeadsPerMonth from "./ExtraLeadsPerMonth";
import Payments from "./Payments";
import Subscription from "./Subscription";



interface InstantMailboxesProps {

}

const InstantMailboxes = () => {
  const { isLoadingInstantMailboxesRead, instantMailboxesRead } = useAppContext();


  return (
    <Page>
      <PageContent>
        <SectionCard
          withBackButton
          skinny
          title={"Mailing Infrastructure"}
          subTitle={"Setup and Manage your Mailing Infrastructure"}
          backButtonTitle="Back to Profile"
        >
          {isLoadingInstantMailboxesRead ?
            <LoadingSpinner /> :
            <InstantMailboxesProvider instantMailboxesRead={instantMailboxesRead}>
              <>
                <Divider />
                <ExtraLeadsPerMonth />
                <Divider />
                <Costs />
                <Divider />
                <Subscription />
                <Divider />
                <Payments />
              </>
            </InstantMailboxesProvider>
          }
        </SectionCard>
      </PageContent>
    </Page>
  )
}

export default InstantMailboxes;