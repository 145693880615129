import { fetchOutreachService } from '@/utils/requests';
import { snakeToCamelObject } from './object';

export class FetchEventSource extends EventTarget {
  private reader: ReadableStreamDefaultReader<Uint8Array> | null = null;
  private abortController: AbortController;
  private buffer = '';
  private decoder = new TextDecoder();

  constructor(
    private path: string, 
    private options: RequestInit = {}
  ) {
    super();
    this.abortController = new AbortController();
    this.options.signal = this.abortController.signal;
  }

  async start() {
    try {
      const response = await fetchOutreachService(this.path, {
        ...this.options,
        headers: {
          'Accept': 'text/event-stream',
          'Cache-Control': 'no-cache',
          'Content-Type': 'application/json',
          ...this.options.headers,
        },
      });

      if (!response.ok) {
        const errorText = await response.text();
        throw new Error(errorText || `HTTP error! status: ${response.status}`);
      }

      if (!response.body) {
        throw new Error('Response body is null');
      }

      this.reader = response.body.getReader();
      await this.readStream();

    } catch (err: unknown) {
      if (err instanceof Error && err.name !== 'AbortError') {
        this.dispatchEvent(new CustomEvent('error', { 
          detail: err instanceof Error ? err : new Error('Stream failed')
        }));
      }
      throw err;
    }
  }

  private async readStream() {
    try {
      while (true) {
        const { value, done } = await this.reader!.read();


        if (done) {
          console.log('Stream done');
          if (this.buffer.trim()) {
            this.processEvents(this.buffer);
          }
          break;
        }

        if (value) {
          const chunk = this.decoder.decode(value, { stream: true });
          this.buffer += chunk;
          
          const lastNewlineIndex = this.buffer.lastIndexOf('\n\n');
          if (lastNewlineIndex > -1) {
            const completeEvents = this.buffer.slice(0, lastNewlineIndex);
            this.buffer = this.buffer.slice(lastNewlineIndex + 2);
            this.processEvents(completeEvents);
          }
        }
      }
    } catch (err: unknown) {
      if (err instanceof Error && err.name !== 'AbortError') {
        console.error('Stream read error:', err);
        this.dispatchEvent(new CustomEvent('error', { 
          detail: err instanceof Error ? err : new Error('Failed to read stream')
        }));
      }
    } finally {
      console.log('Stream finished');
      this.dispatchEvent(new CustomEvent('done'));
      this.reader?.releaseLock();
    }
  }

  private processEvents(eventsStr: string) {
    const events = eventsStr.split('\n\n').filter(Boolean);

    for (const eventText of events) {
      const event = this.parseEvent(eventText);
      if (event) {
        const { type, data } = event;
        // console.log('Parsed event:', { type, data });
        try {
          const detail = snakeToCamelObject(JSON.parse(data));
          this.dispatchEvent(new CustomEvent(type, { detail }));
        } catch (err) {
          console.warn('Failed to parse event data:', { data, error: err });
          this.dispatchEvent(new CustomEvent('error', { 
            detail: new Error('Failed to parse event data')
          }));
        }
      }
    }
  }

  private parseEvent(eventText: string): { type: string; data: string } | null {
    const lines = eventText.split('\n');
    let eventType = 'message';
    let data = '';

    for (const line of lines) {
      if (line.startsWith('event:')) {
        eventType = line.slice(6).trim();
      } else if (line.startsWith('data:')) {
        data = line.slice(5).trim();
      }
    }

    return data ? { type: eventType, data } : null;
  }

  close() {
    this.abortController.abort();
    if (this.reader) {
      this.reader.cancel().catch(() => {});
      this.reader = null;
    }
  }
}