import { useServiceCall } from "@hooks/useServiceCall";
import { FetchOptions, fetchOutreachService, QueryParams } from "@utils/requests";

export type InstantMailboxesReadParams = {
  lawyerId: string;
};

export const getInstantMailboxesRead = async ({
  lawyerId,
}: InstantMailboxesReadParams) => {

  const queryParams: QueryParams = {
    lawyerId,
  };

  const options: FetchOptions = {
    method: "GET",
    queryParams,
  };

  const response = await fetchOutreachService(
    `/instant-mailboxes`,
    options
  );

  return response;
};

export type InstantMailboxesInvoice = {
  stripeInvoiceId: string
  quantity: number
  paidAt: Date | null
  hostedInvoiceUrl: string | null
};

export type InstantMailboxesHostedInvoice = InstantMailboxesInvoice & {
  hostedInvoiceUrl: string
}

export type InstantMailboxesInvoiceRead = {
  instantMailboxInvoices : InstantMailboxesInvoice[]
};

export type InstantMailboxesRead = {
  totalRequestedFree: number
  totalRequestedPaid: number
  totalAllocatedFree: number
  totalAllocatedPaid: number
  totalActive: number
  invoicesRead: InstantMailboxesInvoiceRead
};

export const useGetInstantMailboxesRead = () =>
  useServiceCall<InstantMailboxesRead, InstantMailboxesReadParams>({
    serviceCall: getInstantMailboxesRead,
  });
