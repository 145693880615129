import { useServiceCall } from "@hooks/useServiceCall";
import { FetchOptions, fetchOutreachService, QueryParams } from "@utils/requests";

export type MailingInfrastructureTagParams = {
  lawyerId: string;
};

export enum MailingInfrastructureTagValue {
  REQUIRED = 'required',
  OPTIONAL = 'optional',
  NONE = 'none',
}

export type MailingInfrastructureTag = {
  tagValue: MailingInfrastructureTagValue
};


export const getMailingInfrastructureTag = async ({
  lawyerId,
}: MailingInfrastructureTagParams) => {

  const queryParams: QueryParams = {
    lawyerId,
    tagName: 'mailing_infrastructure',
  };

  const options: FetchOptions= {
    method: "GET",
    queryParams,
  };


  const response = await fetchOutreachService(
    `/internal/tags/unique`,
    options
  );

  return response;
};

export const useGetMailingInfrastructureTag = () =>
  useServiceCall<MailingInfrastructureTag, MailingInfrastructureTagParams>({
    serviceCall: getMailingInfrastructureTag,
  });
