import { AvailablePricingPlans, Lawyer } from "@/models";
import { useServiceCall } from "@hooks/useServiceCall";
import { FetchOptions, fetchOutreachService } from "@utils/requests";
import { UUID } from "@utils/text";

export type UpgradePlanRequestParams = {
  lawyerId: UUID;
  plan: AvailablePricingPlans;
};

export const upgradePlanRequest = async ({
  lawyerId,
  plan,
}: UpgradePlanRequestParams) => {
  const options: FetchOptions = {
    method: "POST",
    payload: {
      plan,
    },
  };

  const response = await fetchOutreachService(
    `/web/pricing/${lawyerId}/upgrade-request`,
    options
  );

  return response;
};

export const useUpgradePlanRequest = () =>
  useServiceCall<Lawyer, UpgradePlanRequestParams>({
    serviceCall: upgradePlanRequest,
    successMessage: "Plan upgrade request submitted successfully",
    errorMessage: "Failed to submit plan upgrade request",
  });
