import palette from "@design/palette";
import { Box, BoxProps, Stack, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { Watch } from "react-loader-spinner";

type ControlledBoxProps = {
  loading?: boolean;
  loadingMessage?: React.ReactNode | string | React.ReactNode[];
  disabled?: boolean;
  error?: string | null;
  children: React.ReactNode;
  keepContentOnError?: boolean;
} & BoxProps;

const ControlledBox: React.FC<ControlledBoxProps> = ({
  loading = false,
  loadingMessage,
  disabled = false,
  error = null,
  children,
  keepContentOnError = false,
  ...boxProps
}) => {
  const shouldShowContent = !!(!loading && (keepContentOnError || !error));
  const [currentMessageIndex, setCurrentMessageIndex] = useState(0);

  useEffect(() => {
    if (!loading || !Array.isArray(loadingMessage)) return;

    const interval = setInterval(() => {
      const randomDelay = Math.floor(Math.random() * 4000) + 3000; // Random between 3s and 7s
      setCurrentMessageIndex(prev => (prev + 1) % loadingMessage.length);
      clearInterval(interval);
      setTimeout(() => {
        setCurrentMessageIndex(prev => (prev + 1) % loadingMessage.length);
      }, randomDelay);
    }, 1000);

    return () => clearInterval(interval);
  }, [loading, loadingMessage]);

  const renderLoadingMessage = () => {
    if (!loadingMessage) return null;

    if (Array.isArray(loadingMessage)) {
      const message = loadingMessage[currentMessageIndex];
      if (typeof message === "string") {
        return (
          <Typography variant="body1" color={"text.secondary"}>
            {message}
          </Typography>
        );
      }
      return message;
    }

    if (typeof loadingMessage === "string") {
      return (
        <Typography variant="body1" color={"text.secondary"}>
          {loadingMessage}
        </Typography>
      );
    }

    return loadingMessage;
  };

  return (
    <Box p={2} {...boxProps}>
      {loading && (
        <Stack p={4} px={2} direction="row" alignItems="center" spacing={1}>
          <Watch
            height="40"
            width="40"
            radius="24"
            color={palette.primary.main}
            ariaLabel="watch-loading"
            wrapperStyle={{}}
            visible={true}
          />
          {renderLoadingMessage()}
        </Stack>
      )}
      {error && (
        <Typography color="error" variant="body2">
          {error}
        </Typography>
      )}
      {shouldShowContent && <>{children}</>}
    </Box>
  );
};

export default ControlledBox;
