import { useServiceCall } from "@hooks/useServiceCall";
import { FetchOptions, fetchOutreachService } from "@utils/requests";
import { UUID } from "@utils/text";

export type SearchConversationsParams = {
  lawyerId: UUID;
  query: string;
  contactIds?: UUID[];
};

export const searchConversations = async ({
  lawyerId,
  query,
  contactIds,
}: SearchConversationsParams) => {
  const options: FetchOptions = {
    method: "POST",
    queryParams: {
      query,
      page: 1,
      pageSize: 1000,
    },
    payload: {
      onlyContactIds: contactIds,
    },
  };

  const response = await fetchOutreachService(
    `/lawyers/${lawyerId}/messages`,
    options
  );

  return response;
};

interface OutreachMessageSearchResult {
  id: UUID;
  source: "email" | "linkedin";
  contactId: UUID | null;
  lawyerId: UUID | null;
  campaignId: UUID | null;
  companyId: UUID | null;
}

interface OutreachSearchResponse {
  results: OutreachMessageSearchResult[];
  total: number;
}

export const useSearchConversations = () =>
  useServiceCall<OutreachSearchResponse, SearchConversationsParams>({
    serviceCall: searchConversations,
  });
