import { ROUTE_BILLING } from "@/AuthenticatedAppRoutes";
import { PricingPlan } from "@/models";
import NewMessageSvg from "@components/icons/new_message_3.svg";
import { useAppContext } from "@context/AppContext";
import { Alert, Box, Button, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";


export const ConversationIncreaseBudget = ({ center }: { center?: boolean }) => {
  const { setHighlightPaymentSetup, profile } = useAppContext();
  const [isARR, setIsARR] = useState(false);
  const navigate = useNavigate();
  useEffect(() => {
    if (profile?.lawyer?.arrPlan !== PricingPlan.NO_ARR_PLAN) {
      setIsARR(true);
    }
  }, [profile]);

  const onIncreaseBudget = () => {
    if (isARR) {
      onUpgradePlan();
    } else {
      setHighlightPaymentSetup?.(true);
    }
  };

  const onUpgradePlan = () => {
    navigate(ROUTE_BILLING);
  };

  return (
    <Box
      position={"absolute"}
      top={0}
      left={0}
      height={"100%"}
      width={"100%"}
      display={"flex"}
      alignItems={"center"}
      justifyContent={center ? "center" : "flex-end"}
      zIndex={99999}
    >
      <Alert
        severity="error"
        variant="outlined"
        elevation={2}
        icon={
          <img src={NewMessageSvg} alt="New Message" width={100} height={100} />
        }
        action={
          <Button variant="text" size="small" onClick={onIncreaseBudget}>
            {/* Increase Lead Budget */}
            {isARR ? "Upgrade Plan" : "Increase Lead Cap"}
          </Button>
        }
      >
        <Typography variant="body2">
          You have a new lead that you cannot see yet.
        </Typography>
        <Typography variant="caption" color={"text.secondary"}>
          {isARR
            ? "You'll not be able to view this lead unless you upgrade your plan."
            : "You'll not be able to view this lead until your lead cap is increased."}
        </Typography>
        <br />
        <Typography variant="caption" color={"text.secondary"}>
          Changes might take a while to reflect.
        </Typography>
      </Alert>
    </Box>
  );
};
