import { useServiceCall } from "@hooks/useServiceCall";
import { FetchOptions, fetchOutreachService } from "@utils/requests";
import { UUID } from "@utils/text";

export type PatchEmailCampaignParams = {
  campaignId: UUID;
  status?: string;
  subject?: string;
  targetingRequest?: string;
  name?: string;
  isTestMode?: boolean;
  lawyerViewedTime?: Date;
  suggestedTargetingId?: UUID;
  scheduledActivationTime?: Date;
  deleted?: boolean;
};

export const patchEmailCampaign = async ({
  campaignId,
  status,
  subject,
  targetingRequest,
  name,
  isTestMode,
  suggestedTargetingId,
  lawyerViewedTime,
  scheduledActivationTime,
  deleted,
}: PatchEmailCampaignParams) => {
  const options: FetchOptions = {
    method: "PATCH",
    payload: {
      name,
      status,
      subject,
      targetingRequest,
      suggestedTargetingId,
      isTestMode,
      deleted,
      lawyerViewedTime: lawyerViewedTime?.toISOString(),
      scheduledActivationTime: scheduledActivationTime?.toISOString(),
    },
  };

  const response = await fetchOutreachService(
    `/campaigns/${campaignId}`,
    options
  );

  return response;
};

export const usePatchEmailCampaign = () =>
  useServiceCall({
    serviceCall: patchEmailCampaign,
    successMessage: "Campaign updated successfully",
    errorMessage: "Error updating campaign",
    silent: false,
  });
