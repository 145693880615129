import { PricingPlan } from "@/models";
import MonthlyBudget from "@components/MonthlyBudget";
import { useAppContext } from "@context/AppContext";
import { Button, Fade, Stack, TextField, Typography } from "@mui/material";
import { useUpdateLawyer } from "@services/lawyers/updateLawyer";
import { useEffect, useMemo, useState } from "react";

export const MONTHLY_LEAD_THRESHOLD = 1;
export const MONTHLY_COST_THRESHOLD = 0;

export default function TargetLeads() {
  const { lawyerId, profile, avgChargedLeadsThisWeek, fetchProfile } =
    useAppContext();

  // Monthly budget
  const [monthlyBudget, setMonthlyBudget] = useState<number | null>(null);

  // Leads per month
  const [leadsPerMonth, setLeadsPerMonth] = useState<number | null>(null);

  const [arrPlan, setArrPlan] = useState<PricingPlan | null>(null);

  useEffect(() => {
    if (profile?.lawyer?.leadsPerMonth) {
      setLeadsPerMonth(profile?.lawyer?.leadsPerMonth);
    }
  }, [profile?.lawyer?.leadsPerMonth]);

  useEffect(() => {
    if (profile?.lawyer?.arrPlan) {
      setArrPlan(profile?.lawyer?.arrPlan);
    }
  }, [profile?.lawyer?.arrPlan]);

  const isUnderMonthlyLeadThreshold = (leadsPerMonth ?? MONTHLY_LEAD_THRESHOLD) < MONTHLY_LEAD_THRESHOLD;
  const isUnderMonthlyCostThreshold = (monthlyBudget ?? MONTHLY_COST_THRESHOLD) < MONTHLY_COST_THRESHOLD;

  // Update lawyer
  const [patchLawyer, { loading: isPatchingLawyer }] = useUpdateLawyer();

  // Handle leads per day change
  const handleLeadsPerMonthChange = (event: any) => {
    const value = event.target.value;

    // Reset leads per day
    if (!value) {
      setLeadsPerMonth(null);
      return;
    }

    const intValue = parseInt(value, 10);

    // Set leads per day if value is valid
    if (!(1 <= intValue)) return;
    setLeadsPerMonth(intValue);
  };

  const canUpdate = useMemo(() => {
    return (
      arrPlan === PricingPlan.NO_ARR_PLAN &&
      leadsPerMonth !== profile?.lawyer?.leadsPerMonth &&
      leadsPerMonth !== null &&
      !isPatchingLawyer &&
      !isUnderMonthlyLeadThreshold &&
      !isUnderMonthlyCostThreshold
    );
  }, [
    leadsPerMonth,
    profile?.lawyer?.leadsPerMonth,
    arrPlan,
    isPatchingLawyer,
    isUnderMonthlyLeadThreshold,
    isUnderMonthlyCostThreshold,
  ]);

  const handleUpdateLeadsPerMonth = () => {
    if (!lawyerId) return;
    if (!canUpdate) return;

    patchLawyer({
      lawyerId,
      leadsPerMonth,
    }).then(() => {
      fetchProfile?.();
    });
  };

  return (
    <Stack direction="column" spacing={1}>
      <Stack direction="row" spacing={2} alignItems="center">
        <TextField
          id="outlined-number"
          label="Leads per month"
          disabled={arrPlan !== PricingPlan.NO_ARR_PLAN}
          type="number"
          size="small"
          sx={{
            width: 120,
          }}
          InputProps={{
            inputProps: {
              min: "0",
              max: "10",
              step: "1",
            },
          }}
          InputLabelProps={{
            shrink: true,
          }}
          value={leadsPerMonth || ""}
          onChange={handleLeadsPerMonthChange}
        />
        <Fade in={canUpdate}>
          <Button
            variant="contained"
            color="primary"
            onClick={() => handleUpdateLeadsPerMonth()}
          >
            Save
          </Button>
        </Fade>
      </Stack>
      {!!leadsPerMonth && arrPlan === PricingPlan.NO_ARR_PLAN && (
        <MonthlyBudget
          leadsPerMonth={leadsPerMonth}
          onBudgetChange={setMonthlyBudget}
        />
      )}
      {isUnderMonthlyLeadThreshold && (
        <Typography variant="caption" color="error">
          Your target lead count cannot be below {MONTHLY_LEAD_THRESHOLD}. Please contact support if this is causing a problem for you.
        </Typography>
      )}
      {isUnderMonthlyCostThreshold && (
        <Typography variant="caption" color="error">
          Your target budget cannot be below ${MONTHLY_COST_THRESHOLD}. Please contact support if this is causing a problem for you.
        </Typography>
      )}
    </Stack>
  );
}
