import { Prospect } from "@/models";
import ContactBox from "@components/ContactBox";
import ControlledBox from "@components/ControlledBox";
import FewMatchesEmptyState from "@components/FewMatchesEmptyState";
import { useCampaignCreateContext } from "@context/CampaignCreateContext";
import { Masonry } from "@mui/lab";
import EstimatedLeads from "../EstimatedLeads";

const StepProspects = () => {
  const {
    prospects,
    isSearchingProspects,
    isCreatingCampaignRequest,
    campaignRequestError,
    suggestedTargetingId,
    setEstimatedLeadsText,
  } = useCampaignCreateContext();

  return (
    <>
      <ControlledBox
        loading={(isSearchingProspects && !prospects?.length) || isCreatingCampaignRequest}
        loadingMessage={[
          "Breaking down natural language into search criteria",
          "Optimising search criteria",
          "Researching companies",
          "Evaluating companies that match your criteria",
          "Finding contacts that suit your criteria",
          "Scoring contacts to find the best matches",
        ]}
        p={0}
        width={"100%"}
        position={"relative"}
        error={
          campaignRequestError
            ? "Can not search prospects now but you can continue drafting your campaign."
            : null
        }
      >
        {suggestedTargetingId && (
          <EstimatedLeads
            suggestedTargetingId={suggestedTargetingId}
            onEstimatedLeadsTextChange={(leadsText) => {
              setEstimatedLeadsText(leadsText);
            }}
          />
        )}
        {!prospects?.length && <FewMatchesEmptyState />}
        <Masonry
          columns={{
            xs: 1,
            sm: 1,
            md: 2,
            lg: 3,
            xl: 4,
          }}
          spacing={1}
          style={
            {
              // minHeight: 100,
            }
          }
          defaultHeight={100}
        >
          {!!prospects?.length &&
            prospects.map((prospect: Prospect) => (
              <ContactBox key={prospect.contactId} prospect={prospect} />
            ))}
        </Masonry>
      </ControlledBox>
    </>
  );
};

export default StepProspects;
