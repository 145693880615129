import { useAppContext } from "@context/AppContext";
import { LEADS_PER_MAILBOX, MOST_CUSTOMERS_CHOOSE_HOW_MANY_LEADS } from "@pages/profile/InstantMailboxes/constants";
import { InstantMailboxesHostedInvoice, InstantMailboxesInvoice, InstantMailboxesRead } from "@services/instantMailboxes/getInstantMailboxesRead";
import { createContext, useContext, useEffect, useMemo, useState } from "react";



export enum SubscriptionState {
  ACTIVE_OR_ALLOCATED = "ACTIVE_OR_ALLOCATED",
  HAS_PENDING_INVOICES = "HAS_PENDING_INVOICES",
  REQUESTED = "REQUESTED",
  NEEDS_PAYMENT_INFORMATION = "NEEDS_PAYMENT_INFORMATION",
  NOT_SUBSCRIBED = "NOT_SUBSCRIBED",
}

type InstantMailboxesContextProps = {
  instantMailboxesRead: InstantMailboxesRead | null | undefined;
  subscriptionState: SubscriptionState;
  pendingInvoices: InstantMailboxesHostedInvoice[];
  extraLeadsPerMonth: number;
  setExtraLeadsPerMonth: (extraLeadsPerMonth: number) => void;
};

const initialValues: InstantMailboxesContextProps = {
  instantMailboxesRead: null,
  subscriptionState: SubscriptionState.NOT_SUBSCRIBED,
  pendingInvoices: [],
  extraLeadsPerMonth: MOST_CUSTOMERS_CHOOSE_HOW_MANY_LEADS,
  setExtraLeadsPerMonth: (extraLeadsPerMonth: number) => { },
};

const InstantMailboxesContext = createContext<InstantMailboxesContextProps>(initialValues);

export const InstantMailboxesProvider = ({
  children,
  instantMailboxesRead,
}: {
  children: React.ReactNode;
  instantMailboxesRead: InstantMailboxesRead | null | undefined;

}) => {
  const [state, setState] = useState<InstantMailboxesContextProps>(initialValues);
  const { hasPaymentInformation } = useAppContext();

  const setExtraLeadsPerMonth = (extraLeadsPerMonth: number) => {
    setState((prevState) => ({
      ...prevState,
      extraLeadsPerMonth,
    }));
  }


  useEffect(() => {
    if (instantMailboxesRead === null) return;
    if (instantMailboxesRead === undefined) return;

    let totalMailboxes = [
      instantMailboxesRead.totalActive,
      instantMailboxesRead.totalAllocatedFree,
      instantMailboxesRead.totalAllocatedPaid,
      instantMailboxesRead.totalRequestedPaid
    ].reduce((a, b) => a + b, 0);

    const extraLeadsPerMonth = LEADS_PER_MAILBOX * totalMailboxes;

    setState((prevState) => ({
      ...prevState,
      extraLeadsPerMonth,
    }));
  }, [instantMailboxesRead])



  useEffect(() => {
    if (instantMailboxesRead === null) return;
    if (instantMailboxesRead === undefined) return;

    // Get the pending invoices
    const pendingInvoices: InstantMailboxesHostedInvoice[] = [];
    instantMailboxesRead.invoicesRead.instantMailboxInvoices.forEach(
      (invoice: InstantMailboxesInvoice) => {
        if (invoice.paidAt !== null) {
          return;
        }

        if (invoice.hostedInvoiceUrl === null) {
          return;
        }
        pendingInvoices.push({
          ...invoice,
          hostedInvoiceUrl: invoice.hostedInvoiceUrl
        });
      }
    )

    setState((prevState) => ({
      ...prevState,
      pendingInvoices,
    }));


    // Set the subscription state
    const activeOrAllocated = [
      instantMailboxesRead.totalActive,
      instantMailboxesRead.totalAllocatedPaid,
      instantMailboxesRead.totalAllocatedFree
    ].reduce((a, b) => a + b, 0);

    if (activeOrAllocated > 0) {
      setState((prevState) => ({
        ...prevState,
        subscriptionState: SubscriptionState.ACTIVE_OR_ALLOCATED,
      }));
      return;
    }

    if (pendingInvoices.length > 0) {
      setState((prevState) => ({
        ...prevState,
        subscriptionState: SubscriptionState.HAS_PENDING_INVOICES,
      }));
      return;
    }

    if (instantMailboxesRead.totalRequestedPaid > 0) {
      setState((prevState) => ({
        ...prevState,
        subscriptionState: SubscriptionState.REQUESTED,
      }));
      return;
    }

    if (!hasPaymentInformation) {
      setState((prevState) => ({
        ...prevState,
        subscriptionState: SubscriptionState.NEEDS_PAYMENT_INFORMATION,
      }));
      return;
    }

    setState((prevState) => ({
      ...prevState,
      subscriptionState: SubscriptionState.NOT_SUBSCRIBED,
    }));

  }, [instantMailboxesRead, hasPaymentInformation]);


  useEffect(() => {
    setState((prevState) => ({
      ...prevState,
      instantMailboxesRead,
    }));
  }, [instantMailboxesRead]);

  useEffect(() => {
    console.log({ instantMailboxesState: state })
  }, [state])


  const value = useMemo(() => ({
    ...state,
    setExtraLeadsPerMonth,
  }), [state])

  return (
    <InstantMailboxesContext.Provider value={value}>
      {children}
    </InstantMailboxesContext.Provider>
  );
};

export const useInstantMailboxesContext = () => useContext(InstantMailboxesContext);
