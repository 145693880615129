import config from "@/config";
import { PricingPlan } from "@/models";
import SectionCard from "@components/layout/SectionCard";
import Page from "@components/layout/page/Page";
import PageContent from "@components/layout/page/PageContent";
import { useAppContext } from "@context/AppContext";
import { BillingProvider } from "@context/BillingContext";
import { Divider, Stack, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import PaymentSettings from "../PaymentSettings";
import Subscription from "./Subscription";
import TargetLeads from "./TargetLeads";
import Charges from "./charges/Charges";
import PricingPlans from "./pricing/PricingPlans";

export default function BillingPage() {
  const { profile } = useAppContext();

  const [arrPlan, setArrPlan] = useState<PricingPlan | null>(null);

  useEffect(() => {
    if (profile?.lawyer?.arrPlan) {
      setArrPlan(profile?.lawyer?.arrPlan);
    }
  }, [profile?.lawyer?.arrPlan]);

  return (
    <Page>
      <PageContent>
        <SectionCard
          withBackButton
          skinny
          title={"Payments"}
          subTitle={"View and manage your payments"}
          backButtonTitle="Back to Profile"
        >
          <Stack direction={"column"} spacing={3} mb={10} mt={2}>
            {/* Subscription  */}
            {arrPlan === PricingPlan.NO_ARR_PLAN && (
              <>
                <Divider />
                <Stack
                  direction={{
                    xs: "column",
                    sm: "row",
                  }}
                  alignItems="flex-start"
                  spacing={3}
                >
                  <Stack direction={"column"} spacing={1} width={300}>
                    <Typography variant="h6">Pricing</Typography>
                  </Stack>
                  <Subscription />
                </Stack>
              </>
            )}
            {/* Pricing Plan */}
            <Divider />
            <Stack
              direction={{
                xs: "column",
                sm: "row"
              }}
              alignItems="flex-start"
              spacing={3}
            >
              <Stack direction={"column"} spacing={1} width={300}>
                <Typography variant="h6">Pricing Plan</Typography>
                <Typography variant="body2" color="text.secondary">
                  View or choose another pricing plan
                </Typography>
              </Stack>
              <PricingPlans />
            </Stack>
            {/* Payments  */}
            <Divider />
            <Stack
              direction={{
                xs: "column",
                sm: "row",
              }}
              alignItems={"flex-start"}
              spacing={3}
            >
              <Stack direction={"column"} spacing={1} width={300}>
                <Typography variant="h6">Billing</Typography>
              </Stack>
              <PaymentSettings prompt="Complete your payment setup to launch campaigns." return_url={config.STRIPE_CALLBACK_URL} />
            </Stack>

            {/* Target Leads  */}
            <Divider />
            <Stack
              direction={{
                xs: "column",
                sm: "row",
              }}
              alignItems={{
                xs: "flex-start",
                sm: "center",
              }}
              spacing={3}
            >
              <Stack direction={"column"} spacing={1} width={300}>
                <Typography variant="h6">Lead Cap</Typography>
                <Typography variant="body2" color={"text.secondary"}>
                  Limit the number of leads you receive in a month
                </Typography>
              </Stack>
              <TargetLeads />
            </Stack>

            {/* Charges  */}
            <Divider />
            <BillingProvider>
              <Stack direction={"column"} spacing={1} alignItems={"flex-start"}>
                <Typography variant="h6">Charges</Typography>

                {/* {avgChargedLeadsThisWeek ? (
                  <Chip
                    label={`${avgChargedLeadsThisWeek} leads charged per day this week`}
                    color="info"
                    variant="outlined"
                  />
                ) : null} */}
              </Stack>
              <Charges />
            </BillingProvider>
          </Stack>
        </SectionCard>
      </PageContent>
    </Page>
  );
}
