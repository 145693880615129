import { FetchEventSource } from "@utils/sse";
import { UUID } from '@utils/text';
import { useCallback, useEffect, useRef } from 'react';
import useSWR from 'swr';

// Core types remain the same
interface Contact {
  companyId: UUID;
  companyLinkedinUrl: string;
  companyName: string;
  companyPhotoUrl: string;
  companyWebsiteUrl: string;
  contactEmail: string;
  contactFirstName: string;
  contactId: UUID;
  contactLastName: string;
  contactLinkedinUrl: string;
  contactPhotoUrl: string;
  contactTitle: string;
  score: number;
  summary?: string;
  reasoning?: string;
};

interface Estimate {
  minimum_company_count: number;
  maximum_company_count: number;
  company_count: number;
  minimum_contact_count: number;
  maximum_contact_count: number;
  contact_count: number;
  progress: number;
}

interface ContactStreamResult {
  contacts: Contact[];
  estimate: Estimate | null;
  isLoading: boolean;
  error: Error | null;
  isComplete: boolean;
  startSearch: (searchOptions?: FetchOptions) => void;
  stopSearch: () => void;
}

// Reuse existing fetch options type
interface FetchOptions {
  payload?: any;
  query?: any;
  excludeContentTypeHeader?: boolean;
  method?: string;
  headers?: Record<string, string>;
  signal?: AbortSignal;
}

// Base contact stream hook
function useContactStream(
  relativeResource: string,
  searchKey: string[],
  options: FetchOptions = {}
): ContactStreamResult {
  const { data: contacts = [], mutate: mutateContacts } = useSWR<Contact[]>(
    searchKey,
    null,
    { fallbackData: [] }
  );

  const { data: estimate = null, mutate: mutateEstimate } = useSWR<Estimate | null>(
    [...searchKey, 'estimate'],
    null,
    { fallbackData: null }
  );

  const { data: isLoading = false, mutate: mutateLoading } = useSWR<boolean>(
    [...searchKey, 'loading'],
    null,
    { fallbackData: false }
  );

  const { data: error = null, mutate: mutateError } = useSWR<Error | null>(
    [...searchKey, 'error'],
    null,
    { fallbackData: null }
  );

  const eventSourceRef = useRef<FetchEventSource | null>(null);

  const startSearch = useCallback((searchOptions?: FetchOptions) => {
    if (eventSourceRef.current) {
      eventSourceRef.current.close();
      eventSourceRef.current = null;
    }

    mutateContacts([]);
    mutateEstimate(null);
    mutateError(null);
    mutateLoading(true);

    const localOptions = {
      ...options,
      ...(searchOptions ?? {}),
    }

    try {
      const eventSource = new FetchEventSource(relativeResource, localOptions);
      eventSourceRef.current = eventSource;

      eventSource.addEventListener('estimate', ((evt: Event) => {
        try {
          const customEvt = evt as CustomEvent<Estimate>;
          const estimateData = customEvt.detail;
          mutateEstimate(estimateData, false);
        } catch (err) {
          console.error('Error parsing estimate data:', err);
          mutateError(new Error('Failed to parse estimate data'));
          mutateLoading(false);
        }
      }));

      eventSource.addEventListener('contact', ((evt: Event) => {
        try {
          const customEvt = evt as CustomEvent<Contact>;
          const contactData = customEvt.detail;
          console.log("Received contact data", contactData);
          mutateContacts(prev => [...(prev ?? []), contactData], false);
        } catch (err) {
          console.error('Error parsing contact data:', err);
          mutateError(new Error('Failed to parse contact data'));
          mutateLoading(false);
        }
      }));

      eventSource.addEventListener('done', () => {
        mutateLoading(false);
      });

      eventSource.addEventListener('error', (evt: Event) => {
        const customEvt = evt as CustomEvent<Error>;
        const error = customEvt.detail;
        mutateError(error);
        mutateLoading(false);
      });

      eventSource.start();

    } catch (err) {
      console.error('Failed to create EventSource:', err);
      mutateError(err instanceof Error ? err : new Error('Failed to start stream'));
      mutateLoading(false);
    }
  }, [relativeResource, options, mutateContacts, mutateEstimate, mutateError, mutateLoading]);

  const stopSearch = useCallback(() => {
    if (eventSourceRef.current) {
      console.log("Stopping search", { relativeResource, options });
      eventSourceRef.current.close();
      eventSourceRef.current = null;
    }
    mutateLoading(false);
  }, [mutateLoading]);

  useEffect(() => {
    return () => {
      if (eventSourceRef.current) {
        console.log("Stopping search", { relativeResource, options });
        eventSourceRef.current.close();
        eventSourceRef.current = null;
      }
    };
  }, []);

  return {
    contacts,
    estimate,
    isLoading,
    error,
    isComplete: !isLoading && contacts.length > 0,
    startSearch,
    stopSearch
  };
}

// Campaign-specific search hook
export function useCampaignSearchStream(campaignId: string): ContactStreamResult {
  const relativeResource = `campaigns/${campaignId}/contacts/search/stream`;
  const searchKey = ['campaign-search', campaignId];
  
  return useContactStream(relativeResource, searchKey, {
    method: 'GET'
  });
}

export function useTargetingSearchStream(): ContactStreamResult {
  const relativeResource = 'contacts/search/stream';
  const searchKey = ['targeting-search'];

  return useContactStream(relativeResource, searchKey, {
    method: 'POST',
  });
}