import config from "@/config";
import { TrackedEventName, track } from "@/third-party/tracking";
import FormikCountryPicker from "@components/FormikCountryPicker";
import FormikForm from "@components/FormikForm";
import FormikInput from "@components/FormikInput";
import { CheckSingleIcon } from "@components/icons";
import PageTitle from "@components/layout/page/PageTitle";
import { useAppContext } from "@context/AppContext";
import { useUIMessageContext } from "@context/UIMessageContext";
import palette from "@design/palette";
import {
  Alert,
  Box,
  Button,
  ButtonGroup,
  Chip,
  Grid,
  Slide,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { useCreateSubscription } from "@services/subscription/useCreateSubscription";
import { useValidateCouponCode } from "@services/subscription/validateCouponCode";
import {
  Elements,
  PaymentElement,
  useElements,
  useStripe,
} from "@stripe/react-stripe-js";
import { StripeElementsOptions, loadStripe } from "@stripe/stripe-js";
import { formatTimestamp } from "@utils/date";
import { Formik, useFormikContext } from "formik";
import { createRef, useEffect, useMemo, useState } from "react";
import { RotatingLines } from "react-loader-spinner";
import styled from "styled-components";
import * as Yup from "yup";
import PaymentButtons from "./PaymentButtons";

// Load Stripe
const stripePromise = loadStripe(config.STRIPE_API_KEY);

const PaymentFormBox = styled(Box)`
  width: 500px;
`;

const CUSTOMER_LOCATION_REQUIRED_CODE = "customer_tax_location_invalid";

const LOCATION_SCHEMA = Yup.object().shape({
  postalCode: Yup.string().required("Required"),
  countryCode: Yup.string().required("Required"),
});

interface Location {
  postalCode: string;
  countryCode: string;
}

function LocationForm({ onSubmit }: { onSubmit?: (values: any) => void }) {
  const { profile } = useAppContext();
  const initialValues = useMemo(
    () => ({
      postalCode: profile?.lawyer?.postalCode || "",
      countryCode: profile?.lawyer?.countryCode?.toUpperCase() || "",
    }),
    [profile?.lawyer?.id]
  );

  const handleSubmit = ({ postalCode, countryCode }: Location) => {
    onSubmit?.({
      postalCode,
      countryCode: countryCode?.toLowerCase(),
    });
  };

  return (
    <Formik
      enableReinitialize
      initialValues={initialValues}
      validationSchema={LOCATION_SCHEMA}
      onSubmit={handleSubmit}
    >
      {({ handleSubmit }) => (
        <>
          <Typography variant="body1" gutterBottom>
            For Tax Purposes, please provide your location information.
          </Typography>
          <FormikForm>
            <FormikCountryPicker name="countryCode" />
            <FormikPostalCodeField />
            <Button
              variant="contained"
              color="primary"
              onClick={(e) => handleSubmit()}
            >
              Next
            </Button>
          </FormikForm>
        </>
      )}
    </Formik>
  );
}

function FormikPostalCodeField() {
  const {
    values: { countryCode },
  } = useFormikContext<Location>();
  const isUS = !countryCode || countryCode === "US";
  return (
    <FormikInput
      name="postalCode"
      label={isUS ? "Zip Code" : "Postal Code"}
      placeholder={isUS ? "94111" : "SW1W 0NY"}
      autoComplete="auto"
    />
  );
}

function PaymentForm({ prompt, return_url }: { prompt: string, return_url: string }) {
  const { profile } = useAppContext();
  const { showErrorMessage } = useUIMessageContext();

  // Coupon Form
  // TODO(DJRHails): We skip coupon code by default now. Remove this state and logic.
  const [couponCode, setCouponCode] = useState<string | null>(null);
  const [couponText, setCouponText] = useState<string | null>(null);
  const [couponSkipped, setCouponSkipped] = useState<boolean>(true);

  const [
    validateCoupon,
    { data: coupon, loading: validatingCoupon, error: couponError },
  ] = useValidateCouponCode();

  // Validate coupon before creating subscription
  const handleCouponCode = () => {
    if (!profile?.lawyer?.id) return;
    if (!couponText) return;

    validateCoupon({
      lawyerId: profile?.lawyer?.id,
      couponCode: couponText,
    });

    // track("Coupon Code Submitted", {
    //   lawyerId: profile?.lawyer?.id,
    //   couponCode: couponText,
    // });
  };

  // Handle coupon success
  useEffect(() => {
    if (coupon) {
      // track("Coupon Code Validated", {
      //   lawyerId: profile?.lawyer?.id,
      //   couponCode,
      // });

      setCouponCode(couponText);
    }
  }, [coupon]);

  // Create subscription
  const [
    createSubscription,
    {
      data: subscription,
      loading: creatingSubscription,
      error: subscriptionError,
    },
  ] = useCreateSubscription();

  const showCouponForm = useMemo(() => {
    return !couponCode && !couponSkipped;
  }, [couponSkipped, couponCode]);

  const [location, setLocation] = useState<Location | null>(null);

  const unhandledSubscriptionError = useMemo(() => {
    return (
      !!subscriptionError &&
      !subscriptionError?.message?.includes(CUSTOMER_LOCATION_REQUIRED_CODE)
    );
  }, [subscriptionError]);

  const showLocationForm = useMemo(() => {
    return (
      !!subscriptionError &&
      !location &&
      subscriptionError?.message?.includes(CUSTOMER_LOCATION_REQUIRED_CODE)
    );
  }, [subscriptionError, location]);

  const onLocationSubmit = (values: Location) => {
    setLocation(values);
  };

  const shouldCreateSubscription = useMemo(() => {
    return (
      !showCouponForm &&
      !showLocationForm &&
      profile?.lawyer?.id &&
      !creatingSubscription &&
      !subscription &&
      !unhandledSubscriptionError
    );
  }, [
    profile?.lawyer?.id,
    showCouponForm,
    showLocationForm,
    creatingSubscription,
    subscription,
    unhandledSubscriptionError,
  ]);

  useEffect(() => {
    if (shouldCreateSubscription) {
      createSubscription({
        lawyerId: profile?.lawyer?.id!,
        couponCode: couponCode ? couponCode : undefined,
        postalCode: location?.postalCode,
        countryCode: location?.countryCode,
      });
    }
  }, [shouldCreateSubscription]);

  // Handle subscription error
  useEffect(() => {
    if (unhandledSubscriptionError && !showLocationForm) {
      showErrorMessage(
        `We are experiencing issues with your payment. Please try again later.`
      );
    }
  }, [unhandledSubscriptionError, showLocationForm]);

  // Payment Form
  const clientSecret = useMemo(() => {
    return subscription?.latestInvoice?.paymentIntent?.clientSecret;
  }, [subscription?.latestInvoice?.paymentIntent?.clientSecret]);

  // Cache element options due to a bug in stripe
  // https://github.com/stripe/react-stripe-js/issues/296
  const elementOptions = useMemo(() => {
    if (!clientSecret) {
      return null;
    }

    return {
      clientSecret,
      locale: "auto",
      appearance: {
        theme: "stripe",
        variables: {},
      },
    } as StripeElementsOptions;
  }, [clientSecret]);

  const showPaymentForm = useMemo(() => {
    return (
      !!elementOptions &&
      !subscriptionError &&
      !creatingSubscription &&
      !couponError
    );
  }, [elementOptions, subscriptionError, creatingSubscription, couponError]);

  return (
    <PaymentFormBox>
      <Stack direction="column" spacing={1}>
        <Box mb={1}>
          <Alert severity="warning" variant="outlined">
            <Typography variant="body2" color="textSecondary">
              {prompt}
            </Typography>
          </Alert>
        </Box>

        {creatingSubscription ? (
          <Box mt={4} mb={2} textAlign={"center"}>
            <PageTitle
              title={
                <Typography variant="body2" color="textSecondary">
                  Checking your subscription...
                </Typography>
              }
              icon={
                <RotatingLines
                  strokeColor={palette.primary.main}
                  strokeWidth="5"
                  animationDuration="0.75"
                  width="20"
                  visible={true}
                />
              }
            />
          </Box>
        ) : (
          <PaymentButtons />
        )}

        <Slide direction="right" in={showCouponForm} mountOnEnter>
          <Box mt={3}>
            <Typography variant="body2" gutterBottom>
              Do you have a coupon code?
            </Typography>

            <Typography variant="caption" color="textSecondary">
              Use your coupon code to get a discount on your subscription.
            </Typography>
            {couponError && (
              <Box mt={2}>
                <Alert severity="error">
                  Error validating your coupon code. Please make sure you have
                  the correct coupon code.
                </Alert>
              </Box>
            )}
            <Box mt={2}>
              <TextField
                variant="outlined"
                size="small"
                fullWidth
                value={couponText || ""}
                placeholder="Enter your coupon code"
                onChange={(event) => setCouponText(event.target.value)}
              />
            </Box>
            <Box mt={3}>
              <ButtonGroup fullWidth>
                <Button
                  variant="outlined"
                  color="primary"
                  fullWidth
                  disabled={validatingCoupon}
                  onClick={() => {
                    // track("Coupon Code Skipped", {
                    //   lawyerId: profile?.lawyer?.id,
                    // });
                    setCouponSkipped(true);
                  }}
                >
                  Skip Coupon
                </Button>
                <Button
                  variant="contained"
                  color="primary"
                  fullWidth
                  disabled={!couponText || validatingCoupon}
                  onClick={() => handleCouponCode()}
                >
                  Apply Coupon
                </Button>
              </ButtonGroup>
            </Box>
          </Box>
        </Slide>

        <Slide direction="right" in={showLocationForm} mountOnEnter>
          <Box mt={3}>
            <LocationForm onSubmit={onLocationSubmit} />
          </Box>
        </Slide>

        {elementOptions && (
          <Slide direction="right" in={showPaymentForm} mountOnEnter>
            <Box>
              <Elements
                key={profile?.lawyer?.id}
                stripe={stripePromise}
                options={elementOptions}
              >
                <CompletePaymentMethod
                  subscription={subscription}
                  profile={profile}
                  return_url={return_url}
                />
              </Elements>
            </Box>
          </Slide>
        )}
      </Stack>
    </PaymentFormBox>
  );
}

function CompletePaymentMethod({
  profile,
  subscription,
  return_url,
}: {
  profile: any;
  subscription: any;
  return_url: string;
}) {
  const stripe = useStripe();
  const elements = useElements();

  const { refreshCanUseService } = useAppContext();
  const [paymentSubmitted, setPaymentSubmitted] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string | null | undefined>(
    null
  );

  const periodStart = useMemo(() => {
    return formatTimestamp(subscription?.current_period_start);
  }, [subscription]);

  const handleSubmit = async (event: any) => {
    // We don't want to let default form submission happen here,
    // which would refresh the page.
    event.preventDefault();

    if (!stripe || !elements) {
      // Stripe.js has not yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      return;
    }

    if (paymentSubmitted) {
      return;
    }

    setPaymentSubmitted(true);

    // Confirm payment
    const isSetupIntent =
      subscription?.latestInvoice?.paymentIntent?.object === "setup_intent";
    const confirmEndpoint = isSetupIntent
      ? stripe.confirmSetup
      : stripe.confirmPayment;

    const { error } = await confirmEndpoint({
      elements,
      confirmParams: {
        return_url: return_url,
      },
    });

    if (error) {
      // Payment failed
      console.error(error);
      setErrorMessage(error.message);

      track({
        name: TrackedEventName.PAYMENT_FAILED,
        props: {
          lawyerId: profile?.lawyer?.id,
          error: error.message,
        },
      });
      setPaymentSubmitted(false);
    } else {
      // Payment succeeded
      track({
        name: TrackedEventName.PAYMENT_SUCCESS,
        props: {
          lawyerId: profile?.lawyer?.id,
        },
      });
      refreshCanUseService?.(false);
    }
  };

  // Highlight payment setup when payment is ready
  const ref = createRef<HTMLDivElement>();
  const { highlightPaymentSetup, setHighlightPaymentSetup } = useAppContext();
  const [paymentIsReady, setPaymentIsReady] = useState<boolean>(false);

  useEffect(() => {
    if (paymentIsReady && highlightPaymentSetup && ref?.current) {
      ref.current.scrollIntoView({
        behavior: "smooth",
        block: "center",
        inline: "center",
      });
      setHighlightPaymentSetup?.(false);
    }
  }, [highlightPaymentSetup, ref?.current, paymentIsReady]);

  return (
    <Box ref={ref}>
      {errorMessage && (
        <Box mt={2} mb={2}>
          <Alert severity="error">{errorMessage}</Alert>
        </Box>
      )}

      <form onSubmit={(event: any) => handleSubmit(event)}>
        <Grid container direction="column" spacing={2}>
          <Grid item>
            <PaymentElement onReady={() => setPaymentIsReady(true)} />
          </Grid>
          <Grid item>
            <Grid item>
              <Button
                type="submit"
                color="primary"
                variant="contained"
                disabled={paymentSubmitted}
                onClick={() => {
                  track({
                    name: TrackedEventName.PAYMENT_INTENT,
                    props: {
                      lawyerId: profile?.lawyer?.id,
                    },
                  });
                }}
              >
                Save Payment Method
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </form>
    </Box>
  );
}

export default function PaymentSettings({ prompt, return_url }: { prompt: string, return_url: string }) {
  const { hasPaymentInformation } = useAppContext();

  const [isReady, setIsReady] = useState<boolean>(false);

  useEffect(() => {
    if (hasPaymentInformation !== true || hasPaymentInformation !== null) {
      setIsReady(true);
    }
  }, [hasPaymentInformation]);

  const [showPaymentForm, setShowPaymentForm] = useState<boolean>(false);
  useEffect(() => {
    if (!isReady) return;

    setShowPaymentForm(!hasPaymentInformation);
  }, [isReady, hasPaymentInformation]);

  return (
    <Stack direction="column" spacing={2}>
      {isReady && hasPaymentInformation && <PaymentIsOK />}{" "}
      <Slide in={showPaymentForm} mountOnEnter>
        <Box>{!!showPaymentForm && <PaymentForm prompt={prompt} return_url={return_url} />}</Box>
      </Slide>
    </Stack>
  );
}

export const PaymentIsOK = () => {
  return (
    <Stack direction="column" spacing={1}>
      <PaymentButtons />
      <Box>
        <Chip
          label={"Your payments are up to date."}
          color="success"
          variant="outlined"
          icon={<CheckSingleIcon fontSize="small" />}
        />
      </Box>
    </Stack>
  );
};
