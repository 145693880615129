import { useServiceCall } from "@hooks/useServiceCall";
import { FetchOptions, fetchOutreachService } from "@utils/requests";

type GetStripeManageBillingLinkParams = {
  lawyerId: string;
};

export const getStripeManageBillingLink = async ({
  lawyerId,
}: GetStripeManageBillingLinkParams) => {
  const options: FetchOptions = {
    method: "GET",
    queryParams: {
      lawyerId,
      returnUrl: window.location.href,
    },
  };

  const response = await fetchOutreachService(
    `/stripe/manage/billing`,
    options
  );

  return response;
};

export type GetStripeManageBillingLinkResponse = {
  redirectUrl: string;
};

export const useGetStripeManageBillingLink = () =>
  useServiceCall<
    GetStripeManageBillingLinkResponse,
    GetStripeManageBillingLinkParams
  >({
    serviceCall: getStripeManageBillingLink,
  });
