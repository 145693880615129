import { Stack, Typography } from "@mui/material";
import PaymentSettings from "../PaymentSettings";




const Payments = () => {
  return (
    <Stack
      direction={{ sm: "row" }}
      alignItems={{ sm: "center" }}
      spacing={3}
      padding={3}
    >
      <Stack
        direction={"column"}
        alignItems={"flex-start"}
        spacing={1}
        height={"100%"}
        width={300}
      >
        <Typography variant="h5">Payments</Typography>
      </Stack>
      <Stack direction={"column"} spacing={1} >
        <PaymentSettings
          prompt="Complete your payment setup to Set Up Mailbox Infrastructure"
          return_url={window.location.href}
        />
      </Stack>
    </Stack >
  )
}

export default Payments;