import { ServiceResponse, useServiceCall } from "@hooks/useServiceCall";
import { FetchOptions, fetchOutreachService } from "@utils/requests";

type CCEmailMessageParams = {
  emailMessageId: string;
  ccEmail: string;
};

export const ccEmailMessage = async ({
  emailMessageId,
  ccEmail,
}: CCEmailMessageParams) => {
  const options: FetchOptions = {
    method: "POST",
    queryParams: {
      ccEmail,
    },
  };

  const response = await fetchOutreachService(
    `/email-messages/${emailMessageId}/cc`,
    options
  );

  return response;
};

export const useCCEmailMessage = () =>
  useServiceCall<ServiceResponse, CCEmailMessageParams>({
    serviceCall: ccEmailMessage,
    successMessage:
      "We have cc'd you on this email. You should receive it shortly.",
    errorMessage:
      "We were unable to cc you on this email. Please try again later.",
  });
