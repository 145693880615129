import { Stack, Typography } from "@mui/material";
import PaymentSettings from "../PaymentSettings";
import SubscriptionButton from "./SubscriptionButton";




const Subscription = () => {
  return (
    <Stack
      direction={{ sm: "row" }}
      alignItems={{ sm: "center" }}
      spacing={3}
      padding={3}
    >
      <Stack
        direction={"column"}
        alignItems={"flex-start"}
        spacing={1}
        height={"100%"}
        width={300}
      >
        <Typography variant="h5">Your Subscription</Typography>
      </Stack>
      <Stack direction={"column"} spacing={1} >
        <SubscriptionButton />
      </Stack>
    </Stack >
  )
}

export default Subscription;