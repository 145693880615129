import { useServiceCall } from "@hooks/useServiceCall";
import { FetchOptions, fetchOutreachService } from "@utils/requests";

export type CanUseServiceParams = {
  lawyerId: string;
  useCache?: boolean;
};

export const canUseService = async ({
  lawyerId,
  useCache = true,
}: CanUseServiceParams) => {
  const options: FetchOptions = {
    method: "GET",
    queryParams: {
      useCache,
    },
  };

  const response = await fetchOutreachService(
    `/lawyers/${lawyerId}/payments/can-use-kula`,
    options
  );

  return response;
};

type CanUseServiceResponse = {
  canUseKula: boolean;
  hasPaymentInformation: boolean;
  isPaused: boolean;
  chargedLeadsToday: number | null;
  avgChargedLeadsThisWeek: number | null;
  credits: number | null;
  mustBuyInstantMailbox: boolean;
};

export const useCanUseService = () =>
  useServiceCall<CanUseServiceResponse, CanUseServiceParams>({
    serviceCall: canUseService,
  });
