import { useInstantMailboxesContext } from "@context/InstantMailboxesContext";
import { Stack, Typography } from "@mui/material";
import { DOMAIN_COST_PER_LEAD, DOMAIN_COST_PER_YEAR, MAILBOX_COST_PER_LEAD, MAILBOX_COST_PER_MONTH, MAILBOXES_PER_LEAD } from "./constants";






const Costs = () => {
  const { extraLeadsPerMonth } = useInstantMailboxesContext()

  return (
    <Stack
      direction={{ sm: "row" }}
      alignItems={{ sm: "center" }}
      spacing={3}
      padding={3}
    >
      <Stack
        direction={"column"}
        spacing={0}
        width={300}
      >
        <Typography variant="h5">Costs</Typography>
      </Stack>
      <Stack
        direction={"column"}
        alignItems={"flex-start"}
        justifyContent={""}
        spacing={2}
      >
        <Stack
          direction={"row"}
          alignItems={"center"}
          justifyContent={"flex-start"}
        >
          <Stack
            direction={"row"}
            alignItems={"flex-start"}
            justifyContent={"flex-start"}
          >
            <Typography
              variant="body1"
            >
              Domains: ${DOMAIN_COST_PER_YEAR} x {MAILBOXES_PER_LEAD * extraLeadsPerMonth} = ${DOMAIN_COST_PER_LEAD * extraLeadsPerMonth} Charged Yearly
            </Typography>
          </Stack>
        </Stack>

        <Stack
          direction={"row"}
          alignItems={"center"}
          justifyContent={"flex-start"}
        >
          <Stack
            direction={"row"}
            alignItems={"flex-start"}
            justifyContent={"flex-start"}
          >
            <Typography
              variant="body1"
            >
              Mailboxes: ${MAILBOX_COST_PER_MONTH} x {MAILBOXES_PER_LEAD * extraLeadsPerMonth} = ${MAILBOX_COST_PER_LEAD * extraLeadsPerMonth} Charged Monthly
            </Typography>
          </Stack>
        </Stack>
      </Stack>
    </Stack>
  )
}

export default Costs;