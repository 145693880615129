import { useServiceCall } from "@hooks/useServiceCall";
import { fetchOutreachService } from "@utils/requests";
import { UUID } from "@utils/text";

export type FilterChargeIdsParams = {
  lawyerId: string;
  startTime: Date;
  endTime: Date;
};

export const filterChargeIds = async ({
  lawyerId,
  startTime,
  endTime,
}: FilterChargeIdsParams) => {
  const options = {
    method: "GET",
    queryParams: {
      startTime: startTime.toISOString(),
      endTime: endTime.toISOString(),
    },
  };
  const response = await fetchOutreachService(
    `/lawyers/${lawyerId}/payments/charges`,
    options
  );

  return response;
};

export const useFilterChargeIds = () =>
  useServiceCall<Array<UUID>, FilterChargeIdsParams>({
    serviceCall: filterChargeIds,
  });
