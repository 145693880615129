import { EmailAddress } from "@/models";
import { useServiceCall } from "@hooks/useServiceCall";
import { FetchOptions, fetchOutreachService } from "@utils/requests";

export type ReplyCreate = {
  emailMessageId: string;
  content: string;
  intent?: string;
  attachments?: File[];
  replyToAll?: boolean;
  bcc?: EmailAddress[];
  cc?: EmailAddress[];
  to?: EmailAddress[];
};

export const replyToEmailMessage = async ({
  emailMessageId,
  content,
  intent,
  attachments,
  replyToAll,
  bcc,
  cc,
  to,
}: ReplyCreate) => {
  const formData = new FormData();

  formData.append("content", content);
  formData.append("replyToAll", replyToAll ? "true" : "false");

  if (intent) {
    formData.append("intent", intent);
  }

  if (attachments?.length) {
    attachments.forEach((file) => {
      formData.append("attachments", file, file.name);
    });
  }

  if (bcc) {
    formData.append("bcc", JSON.stringify(bcc));
  }

  if (cc) {
    formData.append("cc", JSON.stringify(cc));
  }

  if (to) {
    formData.append("to", JSON.stringify(to));
  }

  const options: FetchOptions = {
    method: "POST",
    body: formData,
    excludeContentTypeHeader: true,
  };

  const response = await fetchOutreachService(
    `/email-messages/${emailMessageId}/reply`,
    options
  );

  return response;
};

export type ReplyEmailResponse = {
  contentHtml: string;
  contentText: string;
  ccIdentities: EmailAddress[];
  bccIdentities: EmailAddress[];
};

export const useReplyToEmailMessage = () =>
  useServiceCall<ReplyEmailResponse, ReplyCreate>({
    serviceCall: replyToEmailMessage,
    successMessage: "Reply sent!",
    errorMessage: "Failed to send reply.",
    silent: false,
  });
