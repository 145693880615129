import { AvailablePricingPlans } from "@/models";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  List,
  ListItem,
  ListItemText,
  Typography,
} from "@mui/material";
import { PlanCardDetails } from "./PlanCard";

const PlanModal = ({
  open,
  onClose,
  plan,
  onUpgrade,
}: {
  open: boolean;
  onClose: () => void;
  plan: AvailablePricingPlans | null;
  onUpgrade: () => void;
}) => {
  if (!plan) return null;

  const handleContactSales = () => {
    if (window.Intercom) {
      window.Intercom("show");
    }
  }

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>{PlanCardDetails[plan].title}</DialogTitle>
      <DialogContent>
        <Typography variant="h6" sx={{ mt: 2 }}>
          {plan === 'GROWTH' && ' from'}{' '}${PlanCardDetails[plan].cost}/month
        </Typography>
        <List>
          {PlanCardDetails[plan].features.map((feature, index) => (
            <ListItem key={index}>
              <ListItemText primary={feature} />
            </ListItem>
          ))}
        </List>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Close</Button>
        <Button onClick={handleContactSales} variant="contained" color="secondary">Contact Sales</Button>
        <Button onClick={onUpgrade} variant="contained" color="primary">
          Upgrade to {PlanCardDetails[plan].title}
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default PlanModal

