// ARR: 
// $1500 per month 
// 10 leads target
// 20 mailboxes assigned
// 2 mailboxes per lead
// each mailbox costs $3 per month => 2 * $3 = $6
// each domain costs $5 per year => 2 * $5 = $10

export const MAILBOXES_PER_LEAD = 2;
export const DOMAINS_PER_LEAD = 2;

export const LEADS_PER_MAILBOX = 1 / MAILBOXES_PER_LEAD;

export const MAILBOX_COST_PER_MONTH = 3; // USD 
export const DOMAIN_COST_PER_YEAR = 5; // USD

export const DOMAIN_COST_PER_LEAD = DOMAIN_COST_PER_YEAR * DOMAINS_PER_LEAD
export const MAILBOX_COST_PER_LEAD = MAILBOX_COST_PER_MONTH * MAILBOXES_PER_LEAD; // USD

export const MOST_CUSTOMERS_CHOOSE_HOW_MANY_LEADS = 10;