import config from "@/config";
import Auth from "@aws-amplify/auth";
import _ from "lodash";
import { camelToSnakeObject, removeUndefinedValues } from "../object";

export const defaultOutreachServiceHeaders: RequestOptions["headers"] = {
  "Access-Control-Allow-Origin": "*",
  "Access-Control-Allow-Credentials": "true",
  "Accept-Encoding": "gzip, deflate, br",
  "Access-Control-Allow-Headers": "Content-Type",
  "Access-Control-Allow-Methods": "*",
  "content-encoding": "gzip",
  // "Content-Type": "application/json",
};

export const defaultOptions: RequestInit = {
  headers: defaultOutreachServiceHeaders,
  mode: "cors",
};

interface RequestOptions extends RequestInit {
  timeout?: number;
}

async function getAuthOptions() {
  try {
    const session = await Auth.currentSession();
    if (!session) {
      return {};
    }

    const token = session.getIdToken().getJwtToken();
    if (!token) {
      return {};
    }
    return {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
  } catch (error) {
    console.error(error);
    return {};
  }
}

async function fetchWithTimeout(
  resource: string,
  options: RequestOptions = {}
): Promise<Response> {
  try {
    const authOptions = await getAuthOptions();
    const { timeout = 2 * 60 * 1000 } = options;

    const controller = new AbortController();
    const id = setTimeout(() => {
      console.error(
        `Request timed out: ${resource}, timeout: ${timeout}, options: ${JSON.stringify(
          options
        )}`
      );
      controller.abort(`Request timed out: ${resource}`);
    }, timeout);

    const mergedOptions = _.merge({}, defaultOptions, options, authOptions);

    const response = await fetch(resource, {
      ...mergedOptions,
      signal: controller.signal,
    });
    clearTimeout(id);

    return response;
  } catch (error) {
    console.error(error);
    throw error;
  }
}

export function mergePaths(apiPath: string, relativePath: string): string {
  const apiPathWithoutTrailingSlash = apiPath.replace(/\/$/, "");
  const relativePathWithoutLeadingSlash = relativePath.replace(/^\//, "");

  return `${apiPathWithoutTrailingSlash}/${relativePathWithoutLeadingSlash}`;
}

export type QueryParams = {
  [key: string]: any;
};

export type FetchOptions = {
  payload?: any;
  queryParams?: QueryParams;
  excludeContentTypeHeader?: boolean;
} & RequestOptions;

export const fetchOutreachService = async (
  endpoint: string,
  options: FetchOptions
): Promise<Response> => {
  /**
   * Call the Outreach Service using fetch()
   * Make sure to always pass in camelCase keys for the payload and queryParams
   *
   * @param endpoint - The endpoint to call
   * @param options - The options to pass to the fetch request
   * @returns A promise that resolves to a Response object
   */

  const payload = options.payload;
  const queryParams = options.queryParams;

  if (payload) {
    let body = camelToSnakeObject(payload);

    // If we're doing a PATCH request, remove undefined values from the payload
    if (options.method === "PATCH") {
      body = removeUndefinedValues(body);
    }

    // Convert array values to JSON strings
    options.body = JSON.stringify(body);
  }

  // Make it easy to pass in a query object with camelCase keys
  const snakeCaseQuery = camelToSnakeObject(queryParams);
  const queryString = new URLSearchParams(snakeCaseQuery).toString();

  let url = mergePaths(config.OUTREACH_SERVICE_URL, endpoint);
  if (queryString) url = `${url}?${queryString}`;

  if (!options.excludeContentTypeHeader) {
    options.headers = {
      ...options.headers,
      "Content-Type": "application/json",
    };
  }

  const res = await fetchWithTimeout(url, options);
  return res;
};

export const fetchWorkspaceLinkedInService = async (
  relativeResource: string,
  options: any = {}
) => {
  return fetchWithTimeout(
    mergePaths(config.WORKSPACE_API_URL!, relativeResource),
    options
  );
};
