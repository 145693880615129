import { AVAILABLE_PRICING_PLANS, AvailablePricingPlans } from "@/models";
import { useAppContext } from "@context/AppContext";
import { Grid, Stack } from "@mui/material";
import { useState } from "react";
import PlanCard from "./PlanCard";
import PlanModal from "./PlanModal";


export default function PricingPlans() {
  const { profile, upgradePlanRequest } = useAppContext();
  const [selectedPlan, setSelectedPlan] = useState<AvailablePricingPlans | null>(null);
  const [modalOpen, setModalOpen] = useState(false);

  const handleViewMore = (plan: AvailablePricingPlans) => {
    setSelectedPlan(plan)
    setModalOpen(true)
  }

  const handleCloseModal = () => {
    setModalOpen(false)
  }

  const handleUpgrade = () => {
    if (selectedPlan && profile?.lawyer?.id) {
      console.log("Upgrading to", selectedPlan)
      upgradePlanRequest({
        lawyerId: profile?.lawyer?.id,
        plan: selectedPlan,
      });
    }
  }
  return (
    <Stack direction={"row"} spacing={2}>
      <Grid container justifyContent="center" spacing={4}>
        {AVAILABLE_PRICING_PLANS.map((plan: AvailablePricingPlans) => (
          <Grid item key={plan}>
            <PlanCard plan={plan} isCurrent={plan === profile?.lawyer?.arrPlan} onViewMore={() => handleViewMore(plan)} />
          </Grid>
        ))}
      </Grid>
      <PlanModal open={modalOpen} onClose={handleCloseModal} plan={selectedPlan} onUpgrade={handleUpgrade} />
    </Stack>
  );
}
