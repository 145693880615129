import { useGetSuggestedTargetingLazyQuery } from "@/graphql/generated";
import { useAppContext } from "@context/AppContext";
import { CircularProgress, Stack, Typography } from "@mui/material";
import { UUID } from "@utils/text";
import { useEffect, useMemo, useState } from "react";

interface EstimatedLeadsProps {
  suggestedTargetingId?: UUID;
  estimatedLeads?: number;
  onEstimatedLeadsTextChange?: (estimatedLeadsText: string) => void;
}

const EstimatedLeads: React.FC<EstimatedLeadsProps> = ({
  suggestedTargetingId,
  estimatedLeads,
  onEstimatedLeadsTextChange,
}) => {
  // Profile
  const { profile } = useAppContext();

  // Get suggested targetting context
  const [
    getSuggestedTargeting,
    { data: suggestedTargetingResponse, loading: isGettingSuggestedTargeting },
  ] = useGetSuggestedTargetingLazyQuery();

  const suggestedTargeting = useMemo(() => {
    return suggestedTargetingResponse?.suggestedTargeting;
  }, [suggestedTargetingResponse]);


  // Poll get the suggested targetting for 60 seconds with 1 second interval
  const [fetchedAll, setFetchedAll] = useState<boolean>(false);

  // Get the initial suggested targetting
  useEffect(() => {
    if (!profile?.lawyer?.id) return;
    if (!suggestedTargetingId) return;

    getSuggestedTargeting({
      variables: {
        suggestedTargetingId,
      },
    });
  }, [profile?.lawyer?.id, suggestedTargetingId]);

  const isStillEstimating = useMemo(() => {
    return suggestedTargeting?.companyCount === null;
  }, [suggestedTargeting?.companyCount]);

  const currentEstimatedLeads = useMemo(() => {
    let leads = null;

    if (estimatedLeads) {
      leads = estimatedLeads;
    } else if (
      suggestedTargeting?.minimumCompanyCount
    ) {
      leads = suggestedTargeting?.minimumCompanyCount;
    }
    else if (
      suggestedTargeting?.companyCount
    ) {
      leads = suggestedTargeting?.companyCount;
    }

    if (leads === null || leads === undefined) {
      return null;
    }

    const salesQualifiedLeads = leads / 1000;
    const salesQualifiedLeadsRounded = 10 * Math.round(salesQualifiedLeads / 10);

    return salesQualifiedLeadsRounded;
  }, [suggestedTargeting?.companyCount, estimatedLeads]);

  const estimatedLeadsText = useMemo(() => {
    if (currentEstimatedLeads === 0) {
      return "< 2 leads";
    }

    if (!currentEstimatedLeads) {
      return null;
    }

    return `${isStillEstimating ? '>' : '~'} ${currentEstimatedLeads} leads`;
  }, [currentEstimatedLeads, isStillEstimating]);

  // Start polling suggested targeting
  // after triggering estimated leads
  const startPollingSuggestedTargeting = () => {
    const interval = setInterval(() => {
      getSuggestedTargeting({
        variables: {
          suggestedTargetingId,
        },
      });
    }, 1000);

    setTimeout(() => {
      setFetchedAll(true);
      clearInterval(interval);
    }, 120000);

    return () => clearInterval(interval);
  };

  // Trigger estimated leads
  useEffect(() => {
    if (!profile?.lawyer?.id) return;
    if (!suggestedTargeting) return;
    if (suggestedTargeting?.companyCount !== null) return;

    return startPollingSuggestedTargeting();
  }, [
    profile?.lawyer?.id,
    suggestedTargeting,
  ]);

  // Report when estimated leads change
  useEffect(() => {
    if (onEstimatedLeadsTextChange && estimatedLeadsText) {
      onEstimatedLeadsTextChange(estimatedLeadsText);
    }
  }, [estimatedLeadsText]);

  // Set fetched all when estimated leads is set
  useEffect(() => {
    if (currentEstimatedLeads) {
      setFetchedAll(true);
    }
  }, [currentEstimatedLeads]);

  // Keep it busy if not fetched all
  const isBusy = useMemo(() => {
    return (
      !fetchedAll || isGettingSuggestedTargeting || isStillEstimating
    );
  }, [fetchedAll, isGettingSuggestedTargeting]);

  const hasEstimatedLeads = useMemo(() => {
    return currentEstimatedLeads !== null && currentEstimatedLeads !== undefined;
  }
    , [currentEstimatedLeads]);

  if (!hasEstimatedLeads) {
    return null;
  }

  return (
    <Stack spacing={1} direction={"row"} alignItems={"center"} my={1}>
      <Typography variant="body2" color={"text.secondary"}>
        Estimated number of sales qualified leads:
      </Typography>
      {estimatedLeadsText ? (
        <Typography variant="body2" color={"text.secondary"}>
          {estimatedLeadsText}
        </Typography>
      ) : null}
      {isBusy && <CircularProgress size={16} />}
    </Stack>
  );
};

export default EstimatedLeads;
