import { useAppContext } from "@context/AppContext";
import { SubscriptionState, useInstantMailboxesContext } from "@context/InstantMailboxesContext";
import { LoadingButton } from "@mui/lab";
import { Button, Typography } from "@mui/material";
import { InstantMailboxesHostedInvoice } from "@services/instantMailboxes/getInstantMailboxesRead";
import { useSubscribeToInstantMailboxes } from "@services/instantMailboxes/subscribeToInstantMailboxes";
import { useEffect, useState } from "react";
import { MAILBOX_COST_PER_LEAD, MAILBOXES_PER_LEAD } from "./constants";


const SubscriptionButton = () => {
  const { profile, getInstantMailboxesRead, setHighlightPaymentSetup, refreshCanUseService } = useAppContext();
  const { extraLeadsPerMonth, subscriptionState, pendingInvoices } = useInstantMailboxesContext()

  const [
    subscribeToInstantMailboxes,
    {
      data: subscribeToInstantMailboxesResponse,
      loading: isLoadingSubscribeToInstantMailboxes
    }
  ] = useSubscribeToInstantMailboxes();

  const [monthlyCost, setMonthlyCost] = useState<number>(0);


  const handlePayInvoice = (pendingInvoice: InstantMailboxesHostedInvoice) => {
    window.open(pendingInvoice.hostedInvoiceUrl, "_blank");
  }

  const handleSubscribe = () => {
    const lawyerId = profile?.lawyer?.id
    const targetCount = extraLeadsPerMonth * MAILBOXES_PER_LEAD;
    if (!lawyerId) return;

    subscribeToInstantMailboxes({ lawyerId, targetCount, returnUrl: window.location.href, })
  }

  useEffect(() => {
    if (subscribeToInstantMailboxesResponse === null) return;

    if (subscribeToInstantMailboxesResponse.invoiceStatus !== 'paid') {
      window.open(subscribeToInstantMailboxesResponse.invoiceUrl, "_blank");
    }

    if (!profile?.lawyer?.id) return;

    getInstantMailboxesRead?.({ lawyerId: profile?.lawyer?.id });

    refreshCanUseService?.(false);

  }, [subscribeToInstantMailboxesResponse]);

  useEffect(() => {
    const mailboxCostPerMonth = extraLeadsPerMonth * MAILBOX_COST_PER_LEAD;
    setMonthlyCost(mailboxCostPerMonth);
  }, [extraLeadsPerMonth])



  if (subscriptionState === SubscriptionState.ACTIVE_OR_ALLOCATED) {
    return (
      <Typography variant="h6" color="green">Active for ${monthlyCost}/Month</Typography>
    )
  }


  if (subscriptionState === SubscriptionState.HAS_PENDING_INVOICES) {
    return (
      <>
        {pendingInvoices.map((pendingInvoice) => (
          <Button
            key={pendingInvoice.stripeInvoiceId}
            variant="contained"
            color="primary"
            onClick={() => handlePayInvoice(pendingInvoice)}
          >
            Pay Invoice
          </Button>
        ))}
      </>
    )
  }
  if (subscriptionState === SubscriptionState.REQUESTED) {
    return (
      <Typography variant="body1">Requested {extraLeadsPerMonth} Lead{extraLeadsPerMonth === 1 ? "" : "s"} Per Month</Typography>
    )
  }

  if (subscriptionState === SubscriptionState.NEEDS_PAYMENT_INFORMATION) {
    return (
      <Typography variant="body1">Complete your payment setup to Set Up Mailbox Infrastructure</Typography>
    )
  }


  return (
    <LoadingButton
      onClick={handleSubscribe}
      variant="contained"
      color="primary"
      loading={isLoadingSubscribeToInstantMailboxes}
    >
      Subscribe
    </LoadingButton>
  )
}

export default SubscriptionButton;