import { useServiceCall } from "@hooks/useServiceCall";
import { FetchOptions, fetchOutreachService, QueryParams } from "@utils/requests";

export type subscribeToInstantMailboxesParams = {
  lawyerId: string;
  targetCount: number
  returnUrl: string
};

export const subscribeToInstantMailboxes = async ({
  lawyerId,
  targetCount,
  returnUrl,
}: subscribeToInstantMailboxesParams) => {

  const queryParams: QueryParams = {
    lawyerId,
    count: targetCount.toString(),
    returnUrl,
  };

  const options: FetchOptions = {
    method: "POST",
    queryParams,
  };

  const response = await fetchOutreachService(
    `/instant-mailboxes/subscribe-to-instant-mailboxes`,
    options
  );

  return response;
};

export type subscribeToInstantMailboxesResponse = {
  success : boolean
  details: string
  invoiceUrl: string
  invoiceStatus: string
};

export const useSubscribeToInstantMailboxes = () =>
  useServiceCall<subscribeToInstantMailboxesResponse, subscribeToInstantMailboxesParams>({
    serviceCall: subscribeToInstantMailboxes,
  });
