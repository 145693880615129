import { useServiceCall } from "@hooks/useServiceCall";
import { fetchOutreachService } from "@utils/requests";

export type MonthlyBudgetParams = {
  lawyerId: string;
  leadsPerMonth?: number;
};

export type MonthlyBudgetResponse = {
  monthlyBudget: number;
};

export const getMonthlyBudget = async ({
  lawyerId,
  leadsPerMonth,
}: MonthlyBudgetParams) => {
  const options = {
    method: "GET",
    queryParams: {
      leadsPerMonth,
    },
  };

  const response = await fetchOutreachService(
    `/lawyers/${lawyerId}/payments/budget`,
    options
  );

  return response;
};

export const useGetMonthlyBudget = () =>
  useServiceCall<MonthlyBudgetResponse, MonthlyBudgetParams>({
    serviceCall: getMonthlyBudget,
  });
